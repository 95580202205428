<template>
  <fragment>
    <div class="flex space-between">
      <div class="col-12" v-if="cDisplayToast">
        <Message class="toast" @close="closeToast" :severity="toastSeverity"> {{toastMessage}}</Message>
      </div>
      <div class="col-6">
        <p class="label">Filters</p>
        <base-filter name="View" :options="viewFilterOptions" type="radio" :filterToggleOn="true" preSelect="transactionView"
          @onFilter="fetchFilteredView('view', $event)" page="reconciliation"/>
        <base-filter name="Earnings Provider" :options="paymentFilterOptions" type="radio" :filterToggleOn="true" :preSelect="getPreSelectValue" :preCheck="preCheck"
          @onFilter="fetchFilteredView('provider', $event)" page="reconciliation"/>
        <base-filter name="Status" :options="statusFilterOptions" type="radio" preSelect="all" :filterToggleOn="true"
          @onFilter="fetchFilteredView('status', $event)" page="reconciliation"/>
        <base-filter name="Type" :options="typeFilterOptions" type="radio" preSelect="allTypes" :filterToggleOn="true"
          @onFilter="fetchFilteredView('invoicetype', $event)" page="reconciliation"/>
      </div>
      <div v-if="displayView != 'transaction view'" class="col-6 p-right-0 cell60" style="width:55%;">
        <div class="flex justify-end earning-container">
          <div class="col-2 p-right-0" v-if="displayView === 'settlement view'">
            <div class="search-container" style="width: 285px">
              <input v-model="searchTerm" class="deposit-search" type="search" placeholder="Search" @keyup.enter="fetchFilteredView('view', ['settlement view'])" />
              <button type="submit"><span v-if="searchIcon()" class="icon-x deposit-icon" @click="resetSearch"></span> <span
                  v-else class="icon-search deposit-icon"></span></button>
            </div>
          </div>
          <div>
            <date-picker v-model="dateRange" range format="DD-MMM-YYYY" :disabled-date="(date) => this.displayView === 'hold'? date >= new Date(): false" @input="fetchFilteredView('date', $event)"></date-picker>
          </div>
          <div v-if="displayView != 'hold to paid' && provider !='ACH WEBHOOK' " class="col-2 tooltip-i p-right-0" @click="downloadReconCsv()">
            <a v-if="!downloadLoader"><img src="@/assets/images/logos/download.svg" /></a>
            <span v-else class="loading"><Loader /></span>
            <span class="tooltip-text summary-tooltip-align">Click to download</span>
          </div>
        </div>
        <div class="flex justify-end earning-container" v-if="displayView != 'hold to paid' && provider !='ACH WEBHOOK' ">
          <div class="hlgt-block margin-right-20 inline-flex">
            <div class="amount-block">
              <p :class="totalView()" class="totals-num">{{ disbursementTotal | formatMoney }}</p>
            </div>
            <p class="totals-text">{{ getGlobalTotalTextLabels().disburse }}</p> 
          </div>
          <div class="hlgt-block inline-flex">
            <div class="amount-block">
              <p :class="totalView()" class="totals-num">{{ formatMoney(settlementTotal, displayView === 'hold') }}</p>
            </div>
            <p class="totals-text">{{ getGlobalTotalTextLabels().settled }}</p>
          </div>
        </div>
      </div>
       <div v-if="displayView === 'transaction view'" class="col-6 p-right-0" style="width:55%">
        <!-- <div class="flex justify-end">
          <div class="col-2 p-right-0" v-if="displayView === 'settlement view'">
            <div class="search-container" style="width: 285px">
              <input v-model="searchTerm" class="deposit-search" type="search" placeholder="Search" @keyup.enter="fetchFilteredView('view', ['settlement view'])" />
              <button type="submit"><span v-if="searchIcon()" class="icon-x deposit-icon" @click="resetSearch"></span> <span
                  v-else class="icon-search deposit-icon"></span></button>
            </div>
          </div>
        </div> -->

        <div v-if="showTable" class="scrollable-table" ref="tableContainer"
        :style="{ right: currentProvider === 'BRANCH' ? '0%' : (currentProvider === 'ACH' ? '15%' : '30%'), top: `${providerBottom-360}px`}"
        >
        <div v-if="providerTransaction.length > 0">
          <table>
            <thead class="provider-card">
            <tr style="position:sticky;top:0;">
              <th class="heading" colspan="3"><p>{{ currentProvider }}</p></th>
            </tr>
            <tr style="position:sticky;top:26px;" >
             <th class="heading box-size"><p>Type</p></th>
             <th class="heading box-size"><p>Date</p></th>
             <th class="heading box-size"><p>Amount</p></th>
            </tr>
          </thead>
          </table>
        </div>
        <div class="table-content">
          <table class="provider-table" v-if="providerTransaction.length > 0">
            <tbody>
            <tr v-for="item in providerTransaction" :key="item.index" style="background:#fff">
              <td style="text-align: left">{{item.pay_type}}</td>
              <td style="text-align: right">{{ item.transaction_date}}</td>
              <td style="text-align: right">{{item.trn_amt}}</td>
            </tr>
          </tbody>
        </table>
        </div>
        <table class="provider-table" v-if="providerTransaction.length <= 0">
          <thead>
            <tr class="provider-card"><th><h6>{{currentProvider}}</h6></th></tr>
            <tr>
             <th style="background:white"><p>No Data Found</p></th>
            </tr>
          </thead>
        </table>
        </div>
        <!-- Due for Disbursements in Transaction View -->
        <p style="font-weight:600">Due for Disbursements  ({{nextPaymentDate}})</p>
        <div class="flex" style="position:relative" v-click-outside="hide" ref="payProviders"> 
          <div class="payProvider-container hlgt-block" @click="handleProvider('ONE')">
            <div class="totals-text">{{ getGlobalTotalTextLabels().one }}</div>
            <div class="amount-block" style="width:95%">
              <p :class="totalView()" class="totals-num">{{ totalONE | formatMoney }}</p>
            </div> 
          </div>
          <div class="payProvider-container hlgt-block" @click="handleProvider('ACH')">
            <div class="totals-text">{{ getGlobalTotalTextLabels().ach }}</div>
            <div class="amount-block" style="width:95%">
              <p :class="totalView()" class="totals-num">{{ totalACH | formatMoney }}</p>
            </div>
          </div>
          <div class="payProvider-container hlgt-block" @click="handleProvider('BRANCH')">
            <div class="totals-text">{{ getGlobalTotalTextLabels().branch }}</div>
            <div class="amount-block" style="width:95%">
              <p :class="totalView()" class="totals-num">{{ totalBRANCH | formatMoney }}</p>
            </div>
          </div>
        </div>
        <!-- <div v-for="item in providerTransaction" :key="item.index" style="display:flex; gap:10px">
          <div>{{formatDate(item.transaction_date)}}</div>
          <div>{{item.trn_amt}}</div>
        </div> -->
      </div>
    </div>
    <!-- ToPay menu (popup) -->
    <div v-show="toPayMenu" :style="{ width: '25%', position: 'absolute', top: `${top-200}px`, left:'400px',zIndex:6, boxShadow: '2px 2px 9px 2px rgba(0,0,0,0.5)' }"
    >
      <div >
        <table v-if="toPayProvider.length > 0">
          <thead class="menu">
            <tr>
              <th style="font-weight:700" colspan="3">To Earn ({{getDate(this.startingDate)}} - {{formatDate(this.endingDate)}})</th>
            </tr>
             <tr style="position:sticky;top:26px;">
               <th class="heading box-size"><p>Provider</p></th>
               <th class="heading box-size"><p>Count</p></th>
               <th class="heading box-size"><p>Amount</p></th>
            </tr>
          </thead>
        </table>
      </div>
      <div class="table-content">
        <table class="provider-table" v-if="toPayProvider.length > 0">
            <tbody>
            <tr v-for="item in toPayProvider" :key="item.index" style="background:#fff">
              <td style="text-align: left" class="box-size">{{item.provider}}</td>
              <td style="text-align: right" class="box-size">{{item.trn_cnt}}</td>
              <td style="text-align: right" class="box-size">{{ item.trn_amt}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <table class="provider-table" v-if="toPayProvider.length <= 0">
        <thead class="menu">
          <tr>
            <th><p>No Data Found</p></th>
          </tr>
        </thead>
      </table>
    </div>
 <!-- ToPaid menu (popup) -->
    <div v-show="paidMenu"  :style="{ width: '25%', position: 'absolute', top: `${paidTop-180}px`, left:'900px',zIndex:6, boxShadow: '2px 2px 9px 2px rgba(0,0,0,0.5)' }">
      <div>
        <table v-if="paidProvider.length > 0">
          <thead class="menu">
            <tr>
              <th style="font-weight:700" colspan="3">Disbursed ({{getDate(this.paidStartingDate)}} - {{formatDate(this.paidEndingDate)}})</th>
            </tr>
             <tr style="position:sticky;top:26px;" >
               <th class="heading box-size"><p>Provider</p></th>
               <th class="heading box-size"><p>Count</p></th>
               <th class="heading box-size"><p>Amount</p></th>
            </tr>
          </thead>
        </table>
      </div>
      <div class="table-content">
        <table class="provider-table" v-if="paidProvider.length > 0">
            <tbody>
            <tr v-for="item in paidProvider" :key="item.index" style="background:#fff">
              <td style="text-align: left" class="box-size">{{item.provider}}</td>
              <td style="text-align: right" class="box-size">{{item.trn_cnt}}</td>
              <td style="text-align: right" class="box-size">{{ item.paid_amt}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <table class="provider-table" v-if="paidProvider.length <= 0">
        <thead class="menu">
          <tr>
            <th><p>No Data Found</p></th>
          </tr>
        </thead>
      </table>
    </div>
    <!-- Daily To pay menu in Transaction view after expansion -->
    <div v-show="dailyToPayMenu" :style="{ width: dailyToPaid?'30%':'25%', position: 'absolute', top: `${dailyTopayTop-200}px`, left:`${dailyTopayLeft}px`,zIndex:6, boxShadow: '2px 2px 9px 2px rgba(0,0,0,0.5)' }">
      <div class="table-content" style="maxHeight:200px">
        <table v-if="dailyToPayProvider.length > 0">
          <thead class="menu">
            <tr style="position:sticky;top:0px;background:#eee">
              <th v-if="dailyToPaid" style="font-weight:700" colspan="4">Disbursed ({{formatDate(transactionDate)}})</th>
              <th v-else style="font-weight:700" colspan="4">To Earn ({{formatDate(transactionDate)}})</th>
            </tr>
             <tr style="position:sticky;top:23px;background:#eee">
              <th v-if="dailyToPaid" class="heading box-size"><p>Settlement Date</p></th>
               <th text-align="center" class="heading box-size"><p :style="{marginBottom:dailyToPaid?'10%':''}">Provider</p></th>
               <th class="heading box-size"><p :style="{marginBottom:dailyToPaid?'10%':''}">Count</p></th>
               <th class="heading box-size"><p :style="{marginBottom:dailyToPaid?'10%':''}">Amount</p></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in dailyToPayProvider" :key="item.index" style="background:#fff">
              <td v-if="item.settlement_date" class="box-size" style="text-align: left">{{item.settlement_date}}</td>
              <td style="text-align: left" class="box-size">{{item.provider}}</td>
              <td style="text-align: right" class="box-size">{{item.trn_cnt}}</td>
              <td v-if="item.trn_amt" class="box-size" style="text-align: right">{{ item.trn_amt}}</td>
              <td v-else class="box-size" style="text-align: right">{{ item.paid_amt}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <table class="provider-table" v-if="dailyToPayProvider.length <= 0">
        <thead class="menu">
          <tr>
            <th><p>No Data Found</p></th>
          </tr>
        </thead>
      </table>
    </div>
    <!-- ACH WEBHOOK MENU -->
    <div v-show="achWebhookMenu" :style="{ width: '60%', position: 'absolute', top: `${webhookStatusTop-200}px`, left:`${webhookStatusLeft}px`,zIndex:6, boxShadow: '2px 2px 9px 2px rgba(0,0,0,0.5)' }">
      <div class="table-content" style="maxHeight:200px">
        <button @click="hideWebhookStatus" style="position: absolute; top: 4px; right: 20px; z-index:6">Close</button> 
        <table v-if="webhookData.length > 0">
          <thead class="menu">
            <tr style="position:sticky;top:0px;background:#eee">
              <th  style="font-weight:900" colspan="4">Webhook Status for {{ achWebhookInvoice }} and status {{ achWebhookStatus }}</th>
            </tr>
             <tr style="position:sticky;top:23px;background:#eee">
              <th text-align="center" class="heading box-size"><p>Unq Pay Ref</p></th>
              <th class="heading box-size"><p>Trans ID</p></th>
              <th class="heading box-size"><p>Trans Date</p></th>
              <th class="heading box-size"><p>Trans Amt</p></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in webhookData" :key="item.index" style="background:#fff">
              <td style="text-align: left; max-width: 150px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;" class="box-size" :title="item.unq_pay_ref">{{ item.unq_pay_ref }}</td>
              <td class="box-size" style="text-align: left">{{ item.transaction_id}}</td>
              <td style="text-align: left" class="box-size">{{item.transaction_date}}</td>
              <td class="box-size" style="text-align: right">{{item.transaction_amount}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <table class="provider-table" v-if="webhookData.length <= 0">
        <thead class="menu">
          <tr>
            <th><p>No Data Found</p></th>
          </tr>
        </thead>
      </table>
    </div>
    <div v-show="isCurrentDate" class="menu-card" style="height: 100px; width: 300px; background:#eee; color: white; position: absolute;z-index: 6; border-radius: 8px; box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);"
    :style="{top:`${infTop}px`,left:`${500}px`,boxShadow: '2px 2px 9px 2px rgba(0,0,0,0.5)'}">
    <div style="padding: 15px;">
        <div style="font-size: 16px; font-weight: bold;color:black;margin-left:20px">{{PopupMessage}}</div>
        <div style="margin-top: 10px; margin-left:92px"><button @click="hidePopup" style="padding: 8px 15px; background: #fff; color: black; border: none; border-radius: 4px; cursor: pointer;">Okay</button></div>
    </div>
    </div>
    <div v-show="displayView === 'settlement view' && provider != 'ACH WEBHOOK'">
      <div class="flex">
        <h6 class="margin-l-16 width-invoice">Invoice</h6>
        <h6 class="margin-disbursements">Disbursements</h6>
      </div>
      <div :class="getPagination()">
      <data-table :sticky-header="true" :entries="settlementViewEntries" :headers="settlementViewHeaders"
        :loading="loader" @onToggle="toggleDisplay" :remoteSort="true" :pagination-total-count="totalPaginationCount" :pagination-per-page="perPage" :pagination-current-page="currentPage"
        @onPaginate="fetchData($event)">
        <template #InvoiceNo="{ item }">
          <div class="tooltip-i">
            <a @click="downloadInvoiceCSV(item,$event)">{{ item.settlement_invoice }}</a>
            <!-- <span class="tooltip-text invoice-tooltip-align">Click to download</span> -->
          </div>
        </template>
        <template #settled_amount="{ item }">{{ item.settled_amount }}</template>
        <template #settlement_date="{ item }">
          <div>{{ replaceHypens(item.invoice_date_pst) }}</div>
        </template>
        <template #InvoiceType="{ item }">
          <div>
            {{ item.pay_type }}
          </div>
        </template>
        <template #Status="{ item }">
          <div class="dot-col-align">
            <div class="dot"
              :class="{ approved: item.status === 'APPROVED', reconciled: ['RECONCILED', 'ADJUSTED'].includes(item.status), pending: item.status === 'PENDING' }">
            </div>
            <div v-if="item.status !== 'RECONCILED'" class="tooltip-i">
              <a @click="openReconStatusModal('settlement-view',item)">{{ item.status }}</a>
              <span class="tooltip-text balance-tooltip-align">Click to change status.</span>
            </div>
            <span v-else>{{ item.status }}</span>
          </div>
        </template>
        <template #disbursement_date="{ item }">
          <table>
            <thead>
              <tr class="tr-height"> </tr>
            </thead>
            <tbody v-if="settlementTableId">
              <tr v-for="(val, index) in settlementCollpaseData[`${item.settlement_invoice}`]"
                :key="`${val.id}-${index}`">
                <div class="accordian-tr settlement-tr">
                  {{ replaceHypens(val.disbursement_date) }}
                </div>
              </tr>
              <tr v-for="(row, index) in settlementCollpaseData" :key="index">
                  <div v-if="expandedRow == item.index" class="no-data"> {{settlementCollpaseData.message}}</div>
              </tr>
            </tbody>
          </table>
        </template>
        <template #disbursement_volume="{ item }">
          <table>
            <thead>
              <tr class="tr-height"> {{ item.disbursed_volume }} </tr>
            </thead>
            <tbody v-if="settlementTableId">
              <tr v-for="(val, index) in settlementCollpaseData[`${item.settlement_invoice}`]"
                :key="`${val.id}-${index}`">
                <div class="accordian-tr settlement-tr">
                  {{ val.disbursed_volume }}
                </div>
              </tr>
               <tr v-for="(row, index) in settlementCollpaseData" :key="index">
                  <div v-if="expandedRow == item.index" class="no-data"> {{settlementCollpaseData.message}}</div>
              </tr>
            </tbody>
          </table>
        </template>
        <template #disbursement_amount="{ item }">
          <table>
            <thead>
              <tr class="tr-height"> {{ item.disbursed_amount | formatMoney }} </tr>
            </thead>
            <tbody v-if="settlementTableId">
              <tr v-for="(val, index) in settlementCollpaseData[`${item.settlement_invoice}`]"
                :key="`${val.id}-${index}`">
                <div class="accordian-tr settlement-tr">
                  {{ val.disbursed_amount | formatMoney }}
                </div>
              </tr>
               <tr v-for="(row, index) in settlementCollpaseData" :key="index">
                  <div v-if="expandedRow == item.index" class="no-data"> {{settlementCollpaseData.message}}</div>
              </tr>
            </tbody>
          </table>
        </template>
        <!-- <template v-if="provider == 'ACH'" #trigger_csv ="{item}">
          <div  style="width: 108px;">
            <a 
            class="button secondary" 
            @click="reTrigger_csv(item, $event)" 
            style="width: 127px;padding-top: 3px;padding-bottom: 3px;font-size:14px" 
            :class="{ disabled: isDisabled|| item.settlement_invoice.includes('nxt')}" 
            :disabled="isDisabled || item.settlement_invoice.includes('nxt')">ReUpload csv</a>
          </div>
        </template> -->
        <template #Expand="{ item }">
          <div @click="toggleSettlementView(item)" :class="{ active: isactive === item.index }" class="toggle-icon">
            <i :class="{ 'icon-chevron-up': isactive === item.index, 'icon-chevron-down': isactive !== item.index }"></i>
          </div>
        </template>
      </data-table>
      </div>
    </div>
    <div v-show="displayView === 'hold'">
      <data-table :sticky-header="true" :entries="holdViewEntries" :headers="holdViewHeaders"
        :loading="loader" @onToggle="toggleDisplay" :remoteSort="true" :pagination-total-count="totalPaginationCount" :pagination-per-page="perPage" :pagination-current-page="currentPage"
        @onPaginate="fetchData($event)">
        <template #partnerID="{ item }">
          <a @click="redirectProfile(item['partnerID'])">{{ `..........${item['partnerID'].substr(-4)}` }}</a>
        </template>
        <template #DriverName="{ item }">{{ item.DriverName }}</template>
        <template #email="{ item }">{{ item.email }}</template>
        <template #phone="{ item }">{{ item.phone }}</template>
        <template #last_failed_provider="{ item }">{{ item.last_failed_provider }}</template>
        <template #amount="{ item }">{{ item.amount }}</template>
        </data-table>
    </div>
    <!-- <div v-else class="well text-center no-contents">
      <slot name="noContents">
        <p>No records found</p>
      </slot>
    </div> -->
    <!-- TRANSACTION VIEW -->
    <div v-show="displayView === 'transaction view'">
      <div style="posistion:relative;" >
      <data-table :sticky-header="true" :entries="transactionViewEntries" :headers="transactionViewHeaders"
        :loading="loader" @onToggle="toggleDisplay" :remoteSort="true" :pagination-total-count="totalPaginationCount" :pagination-per-page="perPage" :pagination-current-page="currentPage"
        @onPaginate="fetchData($event)" :displayView="displayView" @date-emitted="fetchDate" :provider="provider"
        @download-emitted="selectDownload">
        <template #transaction_date="{ item }">
          <!-- <div>{{ formatDate(item.transaction_date) }}</div> -->
          <table>
            <thead>
              <tr :class="transactionTable">{{item.starting_date}} - {{item.transaction_enddate}}</tr>
            </thead>
            <tbody v-if="transactionTableId" class="child-rows">
              <div>
               <tr v-for="(val, index) in transactionCollpaseData[`${item.transaction_date.substr(0,10)}_${item.pay_type}`]" :key="`${val.id}-${index}`">
                <div class="accordian-tr" :class="getHeight(val)">
                  <a @click="downloadDailyTransaction(val.transaction_date,val,$event)">{{ val.transaction_date }}</a>
                </div>
              </tr>
              </div>
            </tbody>
          </table>
        </template>
        <template #pay_type="{ item }">
          <table>
            <thead>
              <tr :class="transactionTable">{{item.pay_type}}</tr>
            </thead>
            <tbody v-if="transactionTableId" class="child-rows">
              <div>
               <tr v-for="(val, index) in transactionCollpaseData[`${item.transaction_date.substr(0,10)}_${item.pay_type}`]" :key="`${val.id}-${index}`">
                <div class="accordian-tr" :class="getHeight(val)">
                  {{ val.pay_type }}
                </div>
              </tr>
              </div>
            </tbody>
          </table>
        </template>
        <!-- <template #tot_vol="{ item }">{{ item.tot_vol }}</template> -->
         <template #tot_vol="{ item }">
           <table>
            <thead>
              <tr :class="transactionTable">{{item.tot_vol}}</tr>
            </thead>
            <tbody v-if="transactionTableId" class="child-rows">
              <div>
               <tr v-for="(val, index) in transactionCollpaseData[`${item.transaction_date.substr(0,10)}_${item.pay_type}`]" :key="`${val.id}-${index}`">
                <div class="accordian-tr" :class="getHeight(val)">
                  {{ val.tot_vol }}
                </div>
              </tr>
              </div>
            </tbody>
          </table>
         </template>
        <!-- <template #tot_amt="{ item }">{{ item.tot_amt }}</template> -->
         <template #tot_amt="{ item }">
          <table>
            <thead>
              <tr :class="transactionTable">{{item.tot_amt}}</tr>
            </thead>
            <tbody v-if="transactionTableId" class="child-rows">
              <div>
               <tr v-for="(val, index) in transactionCollpaseData[`${item.transaction_date.substr(0,10)}_${item.pay_type}`]" :key="`${val.id}-${index}`">
                <div class="accordian-tr" :class="getHeight(val)">
                  {{ val.tot_amt }}
                </div>
              </tr>
              </div>
            </tbody>
          </table>
         </template>
        <!-- <template #to_pay_vol="{ item }">{{ item.to_pay_vol }}</template> -->
        <template #to_pay_vol="{ item }">
          <table>
            <thead>
              <div v-if="item.to_pay_vol > 0">
                <a  v-click-outside="hidetoPayMenu"><tr @click="handleTableHeaderClick(item,$event)">{{item.to_pay_vol}}</tr></a>
              </div>
              <div v-else>
                <tr>{{item.to_pay_vol}}</tr>
              </div>
              
            </thead>
            <tbody v-if="transactionTableId" class="child-rows">
              <div>
               <tr v-for="(val, index) in transactionCollpaseData[`${item.transaction_date.substr(0,10)}_${item.pay_type}`]" :key="`${val.id}-${index}`">
                <div class="accordian-tr" :class="getHeight(val)">
                  <div v-if="val.to_pay_vol > 0">
                    <a @click="handleDailytoPay(val,$event)" v-click-outside="hideDailyToPayMenu">{{ val.to_pay_vol }}</a>
                  </div>
                  <div v-else>
                    {{ val.to_pay_vol }}
                  </div>
                </div>
              </tr>
              </div>
            </tbody>
          </table>
        </template>
        <!-- <template #to_pay_amt="{ item }">{{ item.amt }}</template> -->
        <template #to_pay_amt="{ item }">
          <table >
            <thead>
              <div v-if="item.to_pay_amt > 0" >
                <a><tr v-click-outside="hidetoPayMenu" @click="handleTableHeaderClick(item,$event)">{{item.to_pay_amt}}</tr></a>
              </div>
              <div v-else>
                <tr>{{item.to_pay_amt}}</tr>
              </div>
            </thead>
            <tbody v-if="transactionTableId">
              <div>
               <tr v-for="(val, index) in transactionCollpaseData[`${item.transaction_date.substr(0,10)}_${item.pay_type}`]" :key="`${val.id}-${index}`">
                <div class="accordian-tr" :class="getHeight(val)">
                  <div v-if="val.to_pay_amt > 0">
                    <a @click="handleDailytoPay(val,$event)" v-click-outside="hideDailyToPayMenu">{{ val.to_pay_amt }}</a>
                  </div>
                  <div v-else>
                    {{ val.to_pay_amt }}
                  </div>
                </div>
              </tr>
              </div>
            </tbody>
          </table>
        </template>
        <!-- <template #hld_vol="{ item }">{{ item.hld_vol }}</template> -->
        <template #hld_vol="{ item }">
          <table>
            <thead>
              <tr :class="transactionTable">{{item.hld_vol}}</tr>
            </thead>
            <tbody v-if="transactionTableId">
              <div>
               <tr v-for="(val, index) in transactionCollpaseData[`${item.transaction_date.substr(0,10)}_${item.pay_type}`]" :key="`${val.id}-${index}`">
                <div class="accordian-tr" :class="getHeight(val)">
                  {{ val.hld_vol }}
                </div>
              </tr>
              </div>
            </tbody>
          </table>
        </template>
        <!-- <template #hld_amt="{ item }">{{ item.hld_amt }}</template> -->
        <template #hld_amt="{ item }">
          <table>
            <thead>
              <tr :class="transactionTable">{{item.hld_amt}}</tr>
            </thead>
            <tbody v-if="transactionTableId">
              <div>
               <tr v-for="(val, index) in transactionCollpaseData[`${item.transaction_date.substr(0,10)}_${item.pay_type}`]" :key="`${val.id}-${index}`">
                <div class="accordian-tr" :class="getHeight(val)">
                  {{ val.hld_amt }}
                </div>
              </tr>
              </div>
            </tbody>
          </table>
        </template>
        <!-- <template #paid_vol="{ item }">{{ item.paid_vol }}</template> -->
        <template #paid_vol="{ item }">
          <table>
            <thead>
              <div v-if="item.paid_vol > 0">
                <a v-click-outside="hidepaidMenu"><tr @click="handleToPaidClick(item, $event)">{{item.paid_vol}}</tr></a>
              </div>
              <div v-else><tr :class="transactionTable">{{item.paid_vol}}</tr></div>
            </thead>
            <tbody v-if="transactionTableId">
              <div>
               <tr v-for="(val, index) in transactionCollpaseData[`${item.transaction_date.substr(0,10)}_${item.pay_type}`]" :key="`${val.id}-${index}`">
                <div class="accordian-tr" :class="getHeight(val)">
                  <div v-if="val.paid_vol > 0">
                    <a @click="handleDailytoPaid(val,$event)" v-click-outside="hideDailyToPayMenu">{{ val.paid_vol }}</a>
                  </div>
                  <div v-else>
                    {{ val.paid_vol }}
                  </div>
                </div>
              </tr>
              </div>
            </tbody>
          </table>
        </template>
        <template #paid_amt="{ item }">
          <table>
            <thead>
              <div v-if="item.paid_amt > 0">
               <a v-click-outside="hidepaidMenu"><tr @click="handleToPaidClick(item, $event)">{{item.paid_amt}}</tr></a>
              </div>
              <div v-else>
                <tr :class="transactionTable">{{item.paid_amt}}</tr>
              </div> 
            </thead>
            <tbody v-if="transactionTableId">
              <div>
               <tr v-for="(val, index) in transactionCollpaseData[`${item.transaction_date.substr(0,10)}_${item.pay_type}`]" :key="`${val.id}-${index}`">
                <div class="accordian-tr" :class="getHeight(val)">
                  <div v-if="val.paid_amt > 0">
                    <a @click="handleDailytoPaid(val,$event)" v-click-outside="hideDailyToPayMenu">{{ val.paid_amt }}</a>
                  </div>
                  <div v-else>
                    {{ val.paid_amt }}
                  </div>
                </div>
              </tr>
              </div>
            </tbody>
          </table>
        </template>
          <template #status="{ item }">
            <table>
            <thead>
              <tr :class="transactionTable">
                <div style="display:flex;gap:5px;align-items:center">
                  <div class="dot"
                    :class="{ approved: item.status === 'APPROVED', reconciled: ['RECONCILED', 'ADJUSTED'].includes(item.status), pending: item.status === 'PENDING' }">
                 </div>
                 <div>
                  {{ item.status }}
                 </div>
               </div>
              </tr>
            </thead>
            <tbody v-if="transactionTableId">
              <div>
               <tr v-for="(val, index) in transactionCollpaseData[`${item.transaction_date.substr(0,10)}_${item.pay_type}`]" :key="`${val.id}-${index}`">
                <div class="accordian-tr" :class="getHeight(val)">
                   <div style="display:flex;gap:5px;align-items:center">
                      <div class="dot"
                        :class="{ approved: val.status === 'APPROVED', reconciled: ['RECONCILED', 'ADJUSTED'].includes(val.status), pending: val.status === 'PENDING' }">
                      </div>
                      <div>
                        {{ val.status }}
                      </div>
                   </div>
                </div>
              </tr>
              </div>
            </tbody>
          </table>
          </template>
        <!-- <template #remarks="{ item }">
          <table>
            <thead>
              <tr :class="transactionTable">{{item.remarks}}</tr>
            </thead>
            <tbody v-if="transactionTableId">
              <div>
               <tr v-for="(val, index) in transactionCollpaseData[`${item.transaction_date.substr(0,10)}_${item.pay_type}`]" :key="`${val.id}-${index}`">
                <div class="accordian-tr" :class="getHeight(val)">
                  {{ val.remarks }}
                </div>
              </tr>
              </div>
            </tbody>
          </table>
        </template> -->
        </data-table>
        </div>
    </div>
    <!-- HOLD TO PAID -->
    <div v-show= "this.displayView === 'hold to paid'">
      <data-table :sticky-header="true" :entries="holdToPaidViewEntries" :headers="holdToPaidViewHeaders"
        :loading="loader" @onToggle="toggleDisplay" :remoteSort="true" :pagination-total-count="totalPaginationCount" :pagination-per-page="perPage" :pagination-current-page="currentPage"
        @onPaginate="fetchData($event)" :displayView="displayView" @date-emitted="fetchDate" :provider="provider"
        @download-emitted="selectDownload">
        <template #pay_type="{ item }">
          <table>
            <thead>
              <tr :class="transactionTable">{{item.pay_type}}</tr>
            </thead>
          </table>
        </template>
        <template #paid_returned_date="{ item }">
          <table>
            <thead>
             <a @click="downloadHoldToPaidCSV(item, $event, item.paid_returned_date)"><tr :class="transactionTable">{{item.paid_returned_date}}</tr></a>
            </thead>
          </table>
        </template>
         <template #one_paid="{ item }">
           <table>
            <thead class="number-center">
              <tr :class="transactionTable">{{item.one_paid || 0}}</tr>
            </thead>
          </table>
         </template>
         <template #branch_paid="{ item }">
          <table>
            <thead class="number-center">
              <tr :class="transactionTable">{{item.branch_paid || 0}}</tr>
            </thead>
          </table>
         </template>
         <template #ach_paid="{ item }">
          <table>
            <thead class="number-center">
              <tr :class="transactionTable">{{item.ach_paid || 0}}</tr>
            </thead>
          </table>
         </template>
         <template #ach_returned="{ item }">
          <table>
            <thead class="number-center">
              <tr :class="transactionTable">{{item.ach_returned || 0}}</tr>
            </thead>
          </table>
         </template>
         </data-table>
    </div>
    <div v-show = "displayView === 'settlement view'  && provider === 'ACH WEBHOOK'">
      <div class="flex">
        <h6 class="margin-l-16">ACH Webhook Status</h6>
      </div>
      <div :class="getPagination()">
      <data-table :sticky-header="true" :entries="achWebhookViewEntries" :headers="achWebhookHeaders"
        :loading="loader" @onToggle="toggleDisplay" :remoteSort="true" :pagination-total-count="totalPaginationCount" :pagination-per-page="perPage" :pagination-current-page="currentPage"
        @onPaginate="fetchData($event)" :displayView="displayView" :provider="provider"  @date-emitted="fetchDate"
        @download-emitted="selectDownload">
        <template #date="{ item }">
          <table>
            <thead>
              <tr :class="transactionTable">{{item.date}}</tr>
            </thead>
          </table>
        </template>
        <template #pay_type="{ item }">
          <table>
            <thead>
             <tr :class="transactionTable">{{item.pay_type}}</tr>
            </thead>
          </table>
        </template>
         <template #invoice_number="{ item }">
           <table>
            <thead>
              <tr :class="transactionTable">{{item.invoice_number}}</tr>
            </thead>
          </table>
         </template>
         <template #invoice_amt="{ item }">
          <table>
            <thead class="number-right">
              <tr :class="transactionTable">{{item.invoice_amt}}</tr>
            </thead>
          </table>
         </template>
         <template #return_amt="{ item }">
          <table>
            <thead class="number-right">
              <tr :class="transactionTable">{{item.return_amt}}</tr>
            </thead>
          </table>
         </template>
         <template #transaction_count="{ item }">
          <table>
            <thead class="number-right">
              <tr :class="transactionTable">{{item.transaction_count}}</tr>
            </thead>
          </table>
         </template>
         <template #created="{ item }">
          <table>
            <thead class="number-right">
              <div v-if="item.created > 0">
                <a @click="achWebhook(item, $event, 'created')"><tr :class="transactionTable">{{item.created}}</tr></a>
              </div>
              <div v-else>
                <a style="text-decoration: none;color:inherit"><tr :class="transactionTable">{{item.created}}</tr></a>
              </div>
            </thead>
          </table>
         </template>
         <template #inProcess="{ item }">
          <table>
            <thead class="number-right">
              <div v-if="item.inProcess > 0">
                <a @click="achWebhook(item, $event, 'In Process')"><tr :class="transactionTable">{{item.inProcess}}</tr></a>
              </div>
              <div v-else>
                <a style="text-decoration: none;color:inherit"><tr :class="transactionTable">{{item.inProcess}}</tr></a>
              </div>
            </thead>
          </table>
         </template>
         <template #completed="{ item }">
          <table>
            <thead class="number-right">
              <tr :class="transactionTable">{{item.completed}}</tr>
            </thead>
          </table>
         </template>
         <template #returned="{ item }">
          <table>
            <thead class="number-right">
              <div v-if="item.returned > 0">
                <a @click="achWebhook(item, $event,'Returned')"><tr :class="transactionTable">{{item.returned}}</tr></a>
              </div>
              <div v-else>
                <a style="text-decoration: none;color:inherit"><tr :class="transactionTable">{{item.returned}}</tr></a>
              </div>
            </thead>
          </table>
         </template>
         </data-table>
        </div>
    </div>
    <reconciliation-transaction-modal recon-modal-name="reconTxnModal" :type="reconModalType" :rowItem="rowItem" />
    <reconciliation-status-modal recon-status-modal-name="reconStatusModal" @close="closeReconStatusModel" :type="reconStatusModalType" :rowItem="statusItem" />
    <div id="recon-loader-modal" class="modal" aria-hidden="true">
      <div :class="{ 'modal-overlay': true }" tabindex="-1" data-micromodal-close="data-micromodal-close">
        <vue-simple-spinner />
      </div>
    </div>
  </fragment>
</template>

<script>
  import { BaseFilter, DataTable, TableHeader, Loader } from "@/components";
  import { Fragment } from "vue-fragment";
  import { reconciliationApi } from "@/http/http.config";
  import { encodeWithParam, downloadFile } from "@/filters";
  //import { mapGetters, mapActions } from 'vuex';
  import micromodal from "micromodal";
  import DateTime from "luxon/src/datetime";
  import ReconciliationTransactionModal from "./ReconciliationTransactionModal.vue";
  import ReconciliationStatusModal from "./ReconciliationStatusModal.vue";
  import Message from 'primevue/message';
  import DatePicker from 'vue2-datepicker';
  import VueSimpleSpinner from "vue-simple-spinner";
  import ClickOutside from "vue-click-outside";
  
  import 'vue2-datepicker/index.css';
  export default {
    components: {
      BaseFilter,
      Fragment,
      DataTable,
      ReconciliationTransactionModal,
      ReconciliationStatusModal,
      Message,
      Loader,
      DatePicker,
      VueSimpleSpinner,
    },
    directives: { ClickOutside },
    data: () => ({
      toggleAccordian: false,
      searchTerm: "",
      displayView: "disbursement view",
      showing: false,
      disburseTableId: false,
      settlementTableId: false,
      holdTableId:false,
      transactionTableId: false,
      disbursementEntries: [],
      settlementEntries: [],
      holdEntries:[],
      transactionEntries: [],
      holdToPaidEntries: [],
      achWebhookEntries: [],
      loader: false,
      disburseCollapseData: {},
      settlementCollpaseData: {},
      transactionCollpaseData: {},
      status: "",
      reconModalType: "disbursement-view",
      reconStatusModalType: "disbursement-view",
      rowItem: {},
      statusItem: {},
      showError: false,
      displayToast: false,
      toastMessage: '',
      toastSeverity: '',
      downloadLoader: false,
      invoiceType: "",
      disbursementTotal: 0,
      settlementTotal: 0,
      dateRange: null,
      provider: "",
      disbAggStartDt: "",
      disbAggEndDt: "",
      invoiceIds: [],
      chevronStatus: false,
      preCheck: false,
      perPage: 50,
      currentPage: 1,
      offset: 0,
      totalPaginationCount: 0,
      expandedRow: null,
      isactive: null,
      globalExpansion: false,
      parentToggle: false,
      totalACH : 0,
      totalONE : 0,
      totalBRANCH : 0,
      isApiCallEnabled : false,
      nextPaymentDate : null,
      providerTransaction : [],
      showTable: false,
      currentProvider: '',
      showProviderMenu : true,
      toPayProvider : [],
      toPayMenu : false,
      paidMenu : false,
      dailyToPayMenu : false,
      startingDate : '',
      endingDate : '',
      paidStartingDate: '',
      paidEndingDate : '',
      paidProvider : [],
      top : 0,
      paidTop : 0,
      dailyTopayTop : 0,
      dailyTopayLeft : 0,
      webhookStatusTop : 0,
      webhookStatusLeft : 0,
      webhookData : [],
      achWebhookMenu : false,
      toPayType : '',
      toPayAmt : '',
      providerBottom : 0,
      dailyToPayProvider : [],
      dailyToPaid : false,
      trasactionDate : '',
      isCurrentDate : false,
      infTop : 0,
      PopupMessage : '',
      achWebhookInvoice : '',
      achWebhookStatus : '',
    }),
    mounted() {
      this.fetchFilteredView('view', ['transaction view']);
      // console.log("Initial isDisabled:", localStorage.getItem('isDisabled'));
      // console.log("Initial reEnableTime:", localStorage.getItem('reEnableTime'));
      // console.log("store is disbaled",this.$store.state.isDisabled); // Access state directly
      // console.log("store reEnable",this.$store.state.reEnableTime);
      // this.$store.dispatch('checkButtonState');
      // this.checkButtonState(); // Check if the button should remain disabled for ACH Reupload
    },
    computed: {
      //...mapGetters(['isDisabled', 'reEnableTime']), // Use mapGetters to access state
      cDisplayToast() {
        return this.displayToast;
      },
      transactionTable(){
        return {
        'tr-height': !this.transactionTableId,
        'tr-height-transaction': this.transactionTableId
      };
      },
      viewFilterOptions() {
        return [
          { id: "transaction-view", name: "transactionView", label: "Transaction View", checked: false, view : "Transactions" },
          // { id: "disbursement-view", name: "disbursementView", label: "Disbursement View", checked: true, view : "Disbursement"},
          { id: "settlement-view", name: "settlementView", label: "Settlement View", checked: false, view : "Settlement"},
          { id: "hold-view", name: "holdView", label: "Hold", checked: false, view : "Hold" },
          { id: "hold-to-paid", name: "holdToPaid", label: "Hold To Paid", checked: false, view: "Failed To Disbursed"}
        ];
      },
      paymentFilterOptions() {
        // if (this.displayView === "disbursement view") {
        //   return [
        //     { id: "allProviders", name: "allProviders", label: "ALL" },
        //     { id: "one", name: "one", label: "ONE" },
        //     { id: "ach", name: "ach", label: "ACH" },
        //     { id: "branch", name: "branch", label: "BRANCH"},
        //     // { id: "hold", name: "hold", label: "HOLD"}
        //   ]
        // } 
        if (this.displayView === "settlement view") {
            return [
              { id: "one", name: "one", label: "ONE" },
              { id: "ach", name: "ach", label: "ACH" },
              { id: "achWebhook", name: "achWebhook", label: "ACH WEBHOOK"}
            ];
        }
        if (this.displayView === "transaction view") {
           return [
            { id: "allProviders", name: "allProviders", label: "ALL" },
           ]
        }
        return [];
      },
      statusFilterOptions() {
        // if (this.displayView === 'disbursement view') {
        //   return [
        //     { id: "all", name: "all", label: "All" },
        //     { id: "pending", name: "pending", label: "Pending" },
        //     { id: "reconciled", name: "reconciled", label: "Reconciled" },
        //     { id: "adjusted", name: "adjusted", label: "Adjusted" }
        //   ];
        // } 
        if(this.displayView === 'settlement view' && this.provider != 'ACH WEBHOOK') {
            return [
              { id: "all", name: "all", label: "All" },
              { id: "pending", name: "pending", label: "Pending" },
              { id: "reconciled", name: "reconciled", label: "Reconciled" },
              { id: "adjusted", name: "adjusted", label: "Adjusted" }
            ];
        }
        if (this.displayView === 'transaction view') {
           return [
              { id: "all", name: "all", label: "All" },
              { id: "pending", name: "pending", label: "Pending" },
              { id: "reconciled", name: "reconciled", label: "Reconciled" },
              { id: "adjusted", name: "adjusted", label: "Adjusted" }
            ];
        }
        return [];
      },
      typeFilterOptions() {
          return [
          { id: "allTypes", name: "allTypes", label: "All" },
          { id: "tips", name: "tips", label: "Tips" },
          { id: "main", name: "main", label: "Earnings" }
          ]
      },

      // typeFilterOptions() {
      //   if(this.displayView != 'hold to paid') {
      //     return [
      //     { id: "allTypes", name: "allTypes", label: "All" },
      //     { id: "tips", name: "tips", label: "Tips" },
      //     { id: "main", name: "main", label: "Earnings" }
      //     ]
      //   }
      //   return [];
      // },
      // disbursementViewHeaders() {
      //   return [
      //     new TableHeader({ headerClass: "cell8 padding-th", label: "Type", name: "InvoiceType", cellClass: "font-12", cellContents: "pay_type" }),
      //     new TableHeader({ headerClass: "cell8 padding-th", label: "Date", name: "disbursement_date", cellContents: "disbursement_date", cellClass: "font-12 breadcrumbs" }),
      //     new TableHeader({ headerClass: "cell5 padding-th", label: "Volume", name: "disbursed_volume", cellContents: "disbursed_volume", cellClass: "font-12" }),
      //     new TableHeader({ headerClass: "cell9 padding-th right-align", label: "Value", name: "disbursed_amount", cellContents: "disbursed_amount", cellClass: "right-align font-12" }),
      //     new TableHeader({ headerClass: "cell8 padding-th", label: "Date", name: "settlement_date", cellClass: "font-12" }),
      //     new TableHeader({ headerClass: "cell5 padding-th", label: "Volume", name: "settled_volume", cellContents: "settled_volume", cellClass: "font-12" }),
      //     new TableHeader({ headerClass: "cell9 padding-th right-align", label: "Value", name: "settled_amount", cellContents: "settled_amount", cellClass: "right-align font-12" }),
      //    // new TableHeader({ headerClass: "cell9 padding-th right-align", label: "Adjusted Amount", name: "adjusted_amount", cellContents: "adjusted_amount", cellClass: "right-align breadcrumbs font-12" }),
      //     new TableHeader({ headerClass: "cell9 padding-th right-align", label: "Balance", name: "Balance", cellContents: "balance", cellClass: "right-align font-12" }),
      //     new TableHeader({ headerClass: "cell11 padding-th", label: "Status", name: "Status", cellContents: "status", cellClass: "font-12" }),
      //     new TableHeader({ headerClass: "cell8 padding-th", label: "Date", name: "invoice_date", cellClass: "font-12" }),
      //     new TableHeader({ headerClass: "cell8 padding-th", label: "Earnings Provider", name: "payment_provider", cellClass: "font-12" }),
      //     new TableHeader({ headerClass: "cell8 padding-th", label: "Invoice No", name: "InvoiceNo", cellClass: "breadcrumbs font-10" }),
      //     new TableHeader({ headerClass: "cell5", cellClass: "table-actions", name: "accordian", accordian: true }),
      //     //new TableHeader({headerClass: "cell15 padding-th", label: "Expand", name: "Expand", cellClass:"table-actions"})
      //   ];
      // },
      settlementViewHeaders() {
        // if(this.provider === 'ACH') {
        //   return [
        //   new TableHeader({ headerClass: "cell12", label: "Type", name: "InvoiceType", cellClass: "font-12" }),
        //   new TableHeader({ headerClass: "cell20", label: "Invoice No", name: "InvoiceNo", cellContents: "settlement_invoice", cellClass: "breadcrumbs font-12" }),
        //   new TableHeader({ headerClass: "cell10", label: "Date", name: "settlement_date", cellContents: "settlement_date", cellClass: "font-12" }),
        //   new TableHeader({ headerClass: "cell8", label: "Volume", name: "settlement_volume", cellContents: "settled_volume", cellClass: "font-12" }),
        //   new TableHeader({ headerClass: "cell10 right-align", label: "Value", name: "settlement_amount", cellContents: "settled_amount", cellClass: "right-align font-12" }),
        //   new TableHeader({ headerClass: "cell10", label: "Date", name: "disbursement_date", cellClass: "font-12" }),
        //   new TableHeader({ headerClass: "cell8", label: "Volume", name: "disbursement_volume", cellClass: "font-12" }),
        //   new TableHeader({ headerClass: "cell12 right-align", label: "Value", name: "disbursement_amount", cellClass: "right-align font-12" }),
        //   new TableHeader({ headerClass: "cell12", label: "Status", name: "Status", cellContents: "status", cellClass: "font-12" }),
        //   new TableHeader({headerClass: "cell20", label: "ReUpload CSV", name: "trigger_csv", cellClass:"table-actions"}),
        //   new TableHeader({ headerClass: "cell12", cellClass: "table-actions", name: "accordian", accordian: true }),
        // ];
        // } else {
          return [
          new TableHeader({ headerClass: "cell12", label: "Type", name: "InvoiceType", cellClass: "font-12" }),
          new TableHeader({ headerClass: "cell20", label: "Invoice No", name: "InvoiceNo", cellContents: "settlement_invoice", cellClass: "breadcrumbs font-12" }),
          new TableHeader({ headerClass: "cell10", label: "Date", name: "settlement_date", cellContents: "settlement_date", cellClass: "font-12" }),
          new TableHeader({ headerClass: "cell8", label: "Volume", name: "settlement_volume", cellContents: "settled_volume", cellClass: "font-12" }),
          new TableHeader({ headerClass: "cell10 right-align", label: "Value", name: "settlement_amount", cellContents: "settled_amount", cellClass: "right-align font-12" }),
          new TableHeader({ headerClass: "cell10", label: "Date", name: "disbursement_date", cellClass: "font-12" }),
          new TableHeader({ headerClass: "cell8", label: "Volume", name: "disbursement_volume", cellClass: "font-12" }),
          new TableHeader({ headerClass: "cell12 right-align", label: "Value", name: "disbursement_amount", cellClass: "right-align font-12" }),
          new TableHeader({ headerClass: "cell12", label: "Status", name: "Status", cellContents: "status", cellClass: "font-12" }),
          new TableHeader({ headerClass: "cell12", cellClass: "table-actions", name: "accordian", accordian: true }),
        ];
//}
        
      },
      holdViewHeaders() {
        return [
          new TableHeader({ headerClass: "cell8", label: "Partner ID", name: "partnerID", cellClass: "font-12" }),
          new TableHeader({ headerClass: "cell20", label: "Partner Name", name: "DriverName", cellClass: "font-12" }),
          new TableHeader({ headerClass: "cell8", label: "Email Id", name: "email", cellClass: "font-12" }),
          new TableHeader({ headerClass: "cell8", label: "Phone", name: "phone", cellClass: "font-12" }),
          new TableHeader({ headerClass: "cell20 text-center", label: "Last Failed Payment Provider", name: "last_failed_provider", cellClass: "font-12" }),
          new TableHeader({ headerClass: "cell8", label: "Amount", name: "amount", cellClass: "font-12" }),
        ];
      },
      transactionViewHeaders() {
        return [
          new TableHeader({ headerClass: "cell20", label: "Date", name: "transaction_date", cellContents:"transaction_date", cellClass: "font-12" }),
          new TableHeader({ headerClass: "cell20", label: "Type", name: "pay_type", cellContents: "pay_type", cellClass: "font-12" }),
          new TableHeader({ headerClass: "cell10", label: "Volume", name: "tot_vol", cellContents: "tot_vol", cellClass: "font-12" }),
          new TableHeader({ headerClass: "cell10", label: "Amount", name: "tot_amt",  cellContents: "tot_amt",cellClass: "font-12" }),
          new TableHeader({ headerClass: "cell10", label: "Volume", name: "to_pay_vol", cellContents: "to_pay_vol", cellClass: "font-12" }),
          new TableHeader({ headerClass: "cell10", label: "Amount", name: "to_pay_amt", cellContents: "to_pay_amt",cellClass: "font-12" }),
          new TableHeader({ headerClass: "cell10", label: "Volume", name: "hld_vol", cellContents: "hld_vol", cellClass: "font-12" }),
          new TableHeader({ headerClass: "cell10", label: "Amount", name: "hld_amt", cellContents: "hld_amt",cellClass: "font-12" }),
          new TableHeader({ headerClass: "cell10", label: "Volume", name: "paid_vol", cellContents: "paid_vol", cellClass: "font-12" }),
          new TableHeader({ headerClass: "cell10", label: "Amount", name: "paid_amt", cellContents: "paid_amt",cellClass: "font-12" }),
          new TableHeader({ headerClass: "cell8", label: "Status", name: "status", cellContents: "status",cellClass: "font-12" }),
          //new TableHeader({ headerClass: "cell12", label: "Remarks", name: "remarks", cellClass: "font-12" }),
          //new TableHeader({headerClass: "cell12", label: "Expand", name: "Expand", cellClass:"table-actions"})
          new TableHeader({ headerClass: "cell12", cellClass: "table-actions", name: "accordian", accordian: true }),
        ];
      },
      holdToPaidViewHeaders() {
        return [
          new TableHeader({ headerClass: "cell20", label: "Type", name: "pay_type", cellContents: "pay_type", cellClass: "font-12" }),
          new TableHeader({ headerClass: "cell20", label: "Date", name: "paid_returned_date", cellContents:"paid_returned_date", cellClass: "font-12" }),
          new TableHeader({ headerClass: "cell10", label: "Disbursed Count", name: "one_paid", cellContents: "one_paid", cellClass: "font-12" }),
          new TableHeader({ headerClass: "cell10", label: "Disbursed Count", name: "branch_paid", cellContents: "branch_paid", cellClass: "font-12" }),
          new TableHeader({ headerClass: "cell10", label: "Disbursed Count", name: "ach_paid", cellContents: "ach_paid", cellClass: "font-12" }),
          new TableHeader({ headerClass: "cell10", label: "Return Count", name: "ach_returned",  cellContents: "ach_returned",cellClass: "font-12" }),
        ];
      },
      achWebhookHeaders() {
        return [
          new TableHeader({ headerClass: "cell2", label: "Date", name: "date", cellContents:"date", cellClass: "font-12" }),
          new TableHeader({ headerClass: "cell2", label: "Type", name: "pay_type", cellContents: "pay_type", cellClass: "font-12" }),
          new TableHeader({ headerClass: "cell5", label: "Invoice number", name: "invoice_number", cellContents: "invoice_number", cellClass: "font-12" }),
          new TableHeader({ headerClass: "cell6", label: "Invoice Amount", name: "invoice_amt",  cellContents: "invoice_amt",cellClass: "font-12" }),
          new TableHeader({ headerClass: "cell5", label: "Return Amount", name: "return_amt",  cellContents: "return_amt",cellClass: "font-12" }),
          new TableHeader({ headerClass: "cell4", label: "Trans Count", name: "transaction_count", cellContents: "transaction_count", cellClass: "font-12" }),
          new TableHeader({ headerClass: "cell2", label: "Created", name: "created", cellContents: "created",cellClass: "font-12" }),
          new TableHeader({ headerClass: "cell2", label: "In Process", name: "inProcess", cellContents: "inProcess", cellClass: "font-12" }),
          new TableHeader({ headerClass: "cell2", label: "Completed", name: "completed", cellContents: "completed",cellClass: "font-12" }),
          new TableHeader({ headerClass: "cell2", label: "Returned", name: "returned", cellContents: "returned", cellClass: "font-12" }),
        ];
      },
      // disbursementViewEntries() {
      //   this.disbursementEntries?.forEach((item) => {
      //     item.balance = Math.abs(Number(Number(item.disbursed_amount) - Number(item.settled_amount)).toFixed(2));
      //   });
      //   return this.disbursementEntries;
      // },
      settlementViewEntries() {
        this.settlementEntries?.forEach((item) => {
          item.disbursement_date = this.settlementCollpaseData[item.settlement_invoice] ? this.settlementCollpaseData[item.settlement_invoice][0]?.disbursement_date : "";
          item.disbursement_volume = this.settlementCollpaseData[item.settlement_invoice]?.length > 0 ? this.settlementCollpaseData[item.settlement_invoice]?.reduce((acc, el) => acc + Number(el.disbursed_volume), 0) : 0;
          item.disburement_value = this.settlementCollpaseData[item.settlement_invoice]?.reduce((acc, el) => acc + Number(el.disbursed_amount), 0);
        })
        return this.settlementEntries;
      },
      holdViewEntries(){
        return this.holdEntries;
      },
      transactionViewEntries() {
        return this.transactionEntries;
      },
      holdToPaidViewEntries() {
        return this.holdToPaidEntries;
      },
      achWebhookViewEntries() {
        return this.achWebhookEntries;
      },
      limit() {
        return this.dateRange != null && this.dateRange[1] != null ? null : 70
      },
      payProvider() {
        if (this.displayView === "disbursement view") {
          return this.isValue(this.provider) ? [this.provider] : ["ONE"];
        } else if (this.displayView === "settlement view" || this.chevronStatus) {
            if(this.provider === "ALL"){
              return this.isValue(this.provider) ? ["ONE"] : ["ONE"];
            } else {
              return this.isValue(this.provider) ? [this.provider] : ["ONE"];
            }
        } else if (this.displayView === "transaction view") {
          return "ALL"
        }
        return this.provider || "ONE"
      },
      getPreSelectValue() {
        if(this.displayView === "disbursement view"){
          return "one"
        } else if (this.displayView === "transaction view"){
          return "allProviders"
        } else if (this.displayView === "settlement view" && this.provider != "ACH WEBHOOK") {
          return "one"
        } else if (this.provider === "ACH WEBHOOK") {
          return "achWebhook"
        } else {
          return "one"
        }
      },
    },
    methods: {
      //...mapActions(['disableButtons']),
      // checkButtonState() {
      // console.log('Button disabled****:', this.isDisabled);
      // console.log('Button re-enable time***:', this.reEnableTime);
    //},
      toggleDisbursementView(item) {
        this.isactive = this.isactive === item.index ? null : item.index;
        if (item){
          if (this.isactive !== null){
            this.toggleDisplay(true);
            this.getAggEntries(item);
          } else {
            this.toggleDisplay(false);
          }
          if (this.expandedRow === item.index) {
              this.expandedRow = null;
            } else {
            this.expandedRow = item.index;
          }
        } else {
          this.toggleDisplay(false);
        }
      },
      toggleSettlementView(item) {
        this.isactive = this.isactive === item.index ? null : item.index;
          if (item) {
            if (this.isactive !== null){
            this.toggleDisplay(true);
            this.getAggEntries(item);
          } else {
            this.toggleDisplay(false);
            }
            if (this.expandedRow === item.index) {
              this.expandedRow = null;
            } else {
            this.expandedRow = item.index;
          }
        }
      },
       getGlobalTotalTextLabels(){
        if(this.displayView === 'transaction view'){
          return {
            one : 'ONE',
            ach : 'ACH',
            branch : 'BRANCH'
          }
        }
        return {
          disburse: this.displayView==='disbursement view' ? 'Current Disbursed Total' : this.displayView==='hold' ? 'Total Hold Amount' : 'Disbursed Total',
          settled: this.displayView==='hold' ? 'Number of Drivers' : 'Settled Total'
        }
      },
      async handleProvider (provider) {
        const payProvidersDiv = this.$refs.payProviders;
        const rect = payProvidersDiv.getBoundingClientRect();
        this.providerBottom = rect.top + window.scrollY;
        
        let data = await reconciliationApi.post("/getProviderTransactions",encodeWithParam({provider}));
        this.providerTransaction = data.data.result;
        if (this.currentProvider === provider) {
          this.showTable = !this.showTable;
        } else {
          this.showTable = true;
        }
        this.currentProvider = provider;
      },
      hide() {
        this.showTable = false;
      },
      hidePopup() {
        this.isCurrentDate = false;
      },
      async handleTableHeaderClick(item,event){
        this.top = event.target.getBoundingClientRect().top - document.body.getBoundingClientRect().top;
        this.startingDate = `${item?.transaction_date.substr(0,10)} 00:00:00.000`;
        this.endingDate =  await this.getWeekEndDate(this.startingDate);

        const params = {
          startDate : this.startingDate,
          endDate : this.endingDate,
          payType : item.pay_type
        }

        const data = (await reconciliationApi.post("/getToPayData", encodeWithParam({params})))?.data;
        this.toPayProvider = data.result;
        if(item.pay_type === this.toPayType &&  item.to_pay_amt === this.toPayAmt){
          this.toPayMenu = !this.toPayMenu;
        } else {
           this.toPayMenu = true
        }
        this.toPayType = item.pay_type;
        this.toPayAmt = item.to_pay_amt;
      },
      hidetoPayMenu() {
        this.toPayMenu = false;
      },
      async handleToPaidClick(item,event) {
        this.paidStartingDate = `${item?.transaction_date.substr(0,10)} 00:00:00.000`;
        this.paidTop = event.target.getBoundingClientRect().top - document.body.getBoundingClientRect().top;
        this.paidEndingDate = await this.getWeekEndDate(this.paidStartingDate);

        const params = {
          startDate : this.paidStartingDate,
          endDate : this.paidEndingDate,
          payType : item.pay_type
        };

        const data = (await reconciliationApi.post("/getPaidData", encodeWithParam({params})))?.data;
        this.paidProvider = data.result;
        if(item.pay_type === this.toPayType &&  item.to_pay_amt === this.toPayAmt){
          this.paidMenu = !this.paidMenu;
        } else {
           this.paidMenu = true
        }
        this.toPayType = item.pay_type;
        this.toPayAmt = item.to_pay_amt;
      },
      hidepaidMenu() {
        this.paidMenu = false;
      },
      async handleDailytoPay(item,event) {
        this.transactionDate = item.transaction_date;
        this.dailyToPaid = false;
        this.dailyTopayTop = event.target.getBoundingClientRect().top - document.body.getBoundingClientRect().top;
        this.dailyTopayLeft = event.target.getBoundingClientRect().left-200;
        const params = {
          transactionDate : `${item?.transaction_date.substr(0,10)}`,
          payType : item.pay_type,
        };
        const data = (await reconciliationApi.post("/getDailytoPayData", encodeWithParam({params})))?.data;
        this.dailyToPayProvider = data.result;
        this.dailyToPayMenu = !this.dailyToPayMenu;
      },
      hideDailyToPayMenu() {
        this.dailyToPayMenu = false;
      },
      async handleDailytoPaid(item,event) {
        this.transactionDate = item.transaction_date;
        this.dailyToPaid = true;
        this.dailyTopayTop = event.target.getBoundingClientRect().top - document.body.getBoundingClientRect().top;
        this.dailyTopayLeft = event.target.getBoundingClientRect().left - 260;
        const params = {
          transactionDate : `${item?.transaction_date.substr(0,10)}`,
          payType : item.pay_type,
        };
        const data = (await reconciliationApi.post("/getDailytoPaidData", encodeWithParam({params})))?.data;
        this.dailyToPayProvider = data.result;
        this.dailyToPayMenu = !this.dailyToPayMenu;
      },
      async achWebhook(item,event,status) {
        this.webhookData = [];
        this.achWebhookInvoice = item.invoice_number;
        this.achWebhookStatus = status;
        this.webhookStatusTop = event.target.getBoundingClientRect().top - document.body.getBoundingClientRect().top;
        this.webhookStatusLeft = event.target.getBoundingClientRect().left-800;
        const params = {
          invoice : item.invoice_number,
          status : status,
        };
        const data = (await reconciliationApi.post("/getWebhookPopup", encodeWithParam({params})))?.data;
        this.webhookData = data.result;
        this.achWebhookMenu = true;
      },
      async hideWebhookStatus () {
        this.achWebhookMenu = false;
        this.webhookData = [];
      },
      async reTrigger_csv (item, event) {
        // Disable buttons and handle the reupload logic after one hr only it will enable
        this.disableButtons();
        let settled_amt = item.settled_amount
        const numberString = settled_amt.replace(/[$,]/g, '');
        const result = parseFloat(numberString);
        this.infTop = (event.target.getBoundingClientRect().top - document.body.getBoundingClientRect().top)-250;
        if (item.disbursed_amount != result) {
          this.PopupMessage = "Settled Amount not matching with Disburse Amount"
          this.isCurrentDate = true;
        } else if (item.disbursed_volume != item.settled_volume) {
          this.PopupMessage = "Settled Volume not matching with Disburse Volume"
          this.isCurrentDate = true;
        } else if (item.settlement_invoice.includes('nxt')) {
          this.PopupMessage = "Invalid file name"
          this.isCurrentDate = true;
        } else {
          const triggered_data = (await reconciliationApi.post("/reTrigger_csv", encodeWithParam({achInvoiceId : item.settlement_invoice,uploadedBy : this.$store.getters.getUserID})))?.data;
          this.PopupMessage = triggered_data.message
          this.isCurrentDate = true;
        }
      },
      getWeekEndDate(param) {
        let endDate = new Date(param);
        endDate.setDate(endDate.getDate() + 6);
        return endDate.toISOString().split('T')[0] + ' 23:59:59.999';
      },
      getPagination(){
        return this.loader || this.totalPaginationCount == 0 ? '' : 'data-table';
      },
      async fetchFilteredView(type, value, resetPagination = true) {
        this.loader = true;
        this.isCurrentDate = false;
        this.disbursementTotal = 0;
        this.settlementTotal = 0;
        this.isactive = null;
        this.expandedRow = null;
        this.toggleDisplay(false);
        this.transactionCollpaseData ={};
        this.achWebhookMenu = false;
        if(resetPagination){
          this.disbursementTotal = 0;
          this.settlementTotal = 0;
        }
        if (type === "view") {
          this.toggleDisplay(false);
          this.displayView = value[0] || this.displayView;
          this.dateRange = [];
        }
        if(this.displayView === 'hold' && resetPagination){
          this.currentPage = 1,
          this.offset = 0,
          this.totalPaginationCount = 0
        }
        // if(this.displayView === 'disbursement view' && resetPagination){
        //   this.currentPage = 1,
        //   this.offset = 0,
        //   this.totalPaginationCount = 0
        // }
        if(this.displayView === 'settlement view' && resetPagination){
          this.currentPage = 1,
          this.offset = 0,
          this.totalPaginationCount = 0
        }
        if(this.displayView === 'transaction view' && resetPagination){
          this.currentPage = 1,
          this.offset = 0,
          this.totalPaginationCount = 0
        }
        if(this.displayView != 'settlement view'&& resetPagination) {
          this.searchTerm = ""
        }
        if(this.displayView === 'hold to paid' && resetPagination){
          this.currentPage = 1,
          this.offset = 0,
          this.totalPaginationCount = 0
        }
        this.checker = this.provider;
        // if (value[0] === "disbursement view") {
        //   this.provider = this.isValue(this.provider) && this.provider != 'ALL' ? this.provider : "ONE"
        // }
        // if (value[0] === "disbursement view") {
        //   this.provider = this.isValue(this.provider) && this.provider === 'ACH WEBHOOK' ? "ONE" : this.provider;
        // }
        if (value[0] === "hold to paid") {
          this.provider = this.isValue(this.provider) && this.provider === 'ACH WEBHOOK' ? "ONE" : this.provider;
        }
        if (type === "status") this.status = value[0]?.toUpperCase();
        if (type === "invoicetype") this.invoiceType = value[0];
        if (type === "provider") {
          this.preCheck = false;
          this.toggleDisplay(false);
          this.provider = value[0]?.toUpperCase();
        }
        if (this.displayView === "transaction view" || this.displayView === 'hold to paid') {
          this.preCheck = true;
          this.provider = "ALL";
        }
        if (this.displayView === "disbursement view" && this.checker === "ACH WEBHOOK") {
          this.preCheck = true;
        }
        if (["BRANCH", "HOLD","ALL"].includes(this.provider) && this.displayView === "settlement view") {
          this.preCheck = true;
          this.provider = "ONE";
        }
        const { start, end } = this.getDateRange();
        const param = {
          status: !this.isValue(this.status) || this.status == "ALL" ? ["PENDING", "RECONCILED", "APPROVED", "ADJUSTED"] : [this.status],
          invoiceId: this.searchTerm,
          type: !this.isValue(this.invoiceType) || this.invoiceType == "all" ? ["Earnings", "Tips"] : [this.getInvoiceType(this.invoiceType)],
          startDate: start,
          endDate: end,
          offset: 0,
          limit: this.limit,
          isAllPaymentProvider: this.payProvider,
          provider: this.payProvider
        };
        // if (this.displayView === "disbursement view") {
        //   param.provider = this.payProvider == "ALL" ? ["ONE","ACH", "BRANCH"] : [this.payProvider];
        //   if(!this.dateRange[0] || !this.dateRange[1]){
        //     param.startDate = null;
        //     param.endDate = null;
        //   }
        //   param.limit = this.perPage;
        //   param.offset = this.offset;
        //   const dData = (await reconciliationApi.post("/getDisbursementView", encodeWithParam({ param })))?.data;
        //   this.disbursementEntries = dData?.result.sort((a,b)=>{
        //     if(a.disbursement_date.substr(0,10) === b.disbursement_date.substr(0,10)){
        //       return a.invoice_report_type < b.invoice_report_type ? -1 : 1;
        //     }else{
        //       return a.disbursement_date.substr(0,10) < b.disbursement_date.substr(0,10) ? 1 : -1;
        //     }
        //   });

        //   [this.disbursementTotal, this.settlementTotal] = [dData.disburseTotal || 0, dData.settleTotal || 0];
        //   this.disbAggStartDt = this.disbursementEntries[this.disbursementEntries.length - 1]?.disbursement_date;
        //   this.disbAggEndDt = this.disbursementEntries[0]?.disbursement_date;
        //   this.totalPaginationCount = Number(dData?.rowCount);
        // } 
        if (this.displayView === "settlement view" && this.provider === 'ACH WEBHOOK') {
          const params = {
            type: !this.isValue(this.invoiceType) || this.invoiceType == "all" ? ["Earnings", "Tips"] : [this.getInvoiceType(this.invoiceType)],
            limit : 50,
            offset : this.offset,
            startDate: start,
            endDate: end,
            invoiceId: this.searchTerm,
          }
          if(!this.dateRange[0] || !this.dateRange[1]){
              params.startDate = null;
              params.endDate = null;
            }
            params.limit = this.perPage;
            params.offset = this.offset;

          const aData = (await reconciliationApi.post("/achWebhookSummary", encodeWithParam({params})))?.data;
          this.achWebhookEntries = aData.result;
          this.totalPaginationCount = Number(aData?.rowCount);
          this.achWebhookAggStartDt = this.achWebhookEntries[this.achWebhookEntries.length - 1]?.paid_returned_on;
          this.achWebhookAggEndDt = this.achWebhookEntries[0]?.paid_returned_on;
        }
        if (this.displayView === "settlement view" && this.provider != 'ACH WEBHOOK') {
          if(!this.dateRange[0] || !this.dateRange[1]){
              param.startDate = null;
              param.endDate = null;
            }
          param.limit = this.perPage;
          param.offset = this.offset;
          const sData = (await reconciliationApi.post("/getSettlementView", encodeWithParam({ param })))?.data;
          this.settlementEntries = sData?.result.sort((a,b)=>{
            if(a.invoice_date_pst === b.invoice_date_pst){
              return a.pay_type<b.pay_type?-1:1;
            }else{
              return a.invoice_date_pst<b.invoice_date_pst?1:-1;
            }
          });

          [this.settlementTotal, this.disbursementTotal] = [sData?.settleTotal || 0, sData?.disburseTotal || 0];
          this.settlementEntries?.forEach(el => { el.settled_amount = this.formatMoney(el.settled_amount) });
          this.invoiceIds = this.settlementEntries.map((el) => el.settlement_invoice);
          this.totalPaginationCount = Number(sData?.rowCount);
        }
        if (this.displayView === "hold") {
          if(!param.startDate) {
            param.startDate = DateTime.now().setZone("America/Los_Angeles").startOf("week").toString().replace("T", " ").substr(0,23);
          }
          if(!param.endDate) { 
            param.endDate = DateTime.now().setZone("America/Los_Angeles").endOf("week").toString().replace("T", " ").substr(0,23);
          }
          param.limit = this.perPage;
          param.offset = this.offset;
          const hData = (await reconciliationApi.post("/getHoldView", encodeWithParam({ param })))?.data;
          this.holdEntries = hData?.result.map((item)=>{
            if(!item.last_failed_provider) item.last_failed_provider = 'No Record';
            return item;
          })
          this.totalPaginationCount = Number(hData?.rowCount);
          this.disbursementTotal = hData.hold_total;
          this.settlementTotal = Number(hData?.rowCount);
        }
        if (this.displayView === "transaction view") {
          const params = {
            status: !this.isValue(this.status) || this.status == "ALL" ? ["PENDING", "RECONCILED", "APPROVED", "ADJUSTED"] : [this.status],
            type: !this.isValue(this.invoiceType) || this.invoiceType == "all" ? ["Earnings", "Tips"] : [this.getInvoiceType(this.invoiceType)],
            limit : 50,
            offset : this.offset,
            startDate: start,
            endDate: end,
          }
          if(!this.dateRange[0] || !this.dateRange[1]){
              params.startDate = null;
              params.endDate = null;
            }
            let duePayments = {
            payType : params.type,
            startDate : params.startDate,
            endDate : params.endDate
          };
          const nextPaymentDateRes = await reconciliationApi.post("/getnextPaymentDate");
          this.nextPaymentDate = nextPaymentDateRes.data.result[0].nextpaydate;
          const totals = (await reconciliationApi.post("/getDuePayments",encodeWithParam({duePayments})))?.data;
          this.totalONE = totals.totalONE.sum;
          this.totalACH = totals.totalACH.sum;
          this.totalBRANCH = totals.totalBRANCH.sum;

          const rData = (await reconciliationApi.post("/getTransactionViewWithOffset", encodeWithParam({params})))?.data;
          this.transactionEntries = rData.result;
          this.totalPaginationCount = Number(rData?.rowCount);
          this.transAggStartDt = this.transactionEntries[this.transactionEntries.length - 1]?.transaction_date;
          this.transAggEndDt = this.transactionEntries[0]?.transaction_date;
        }
        if (this.displayView === "hold to paid") {
          const params = {
            type: !this.isValue(this.invoiceType) || this.invoiceType == "all" ? ["Earnings", "Tips"] : [this.getInvoiceType(this.invoiceType)],
            limit : 50,
            offset : this.offset,
            startDate: start,
            endDate: end,
          }
          if (!this.dateRange[0] || !this.dateRange[1]){
            params.startDate = null;
            params.endDate = null;
          }
          const rData = (await reconciliationApi.post("/getHoldPaidSummary", encodeWithParam({params})))?.data;
          this.holdToPaidEntries = rData.result;
          this.totalPaginationCount = Number(rData?.rowCount);
          this.holdToPaidAggStartDt = this.holdToPaidEntries[this.holdToPaidEntries.length - 1]?.paid_returned_on;
          this.holdToPaidAggEndDt = this.holdToPaidEntries[0]?.paid_returned_on;
        }

        this.loader = false;
      },
      async getAggEntries(param) {
        micromodal.show("recon-loader-modal");
        if (this.displayView === "disbursement view") {
          if (param) {
            param.provider =  this.payProvider == "ALL" ? ["ONE","ACH", "BRANCH"] : [this.payProvider]
            const { data } = await reconciliationApi.post("/getDisburseAggEntries", encodeWithParam({ param : param ,provider : [this.payProvider] }));
            const obj = {};
            data.result?.forEach((el) => {
            const key = `${el?.disbursement_date.substr(0,10)}_${el?.invoice_report_type}`;
            if (obj[key] != undefined && el?.invoice_date != null) obj[key].push(el);
            else if (el?.invoice_date != null) obj[key] = new Array(el);
          });
          this.disburseCollapseData = obj;
          //this.disburseCollapseData[Object.keys(obj)[0]] && this.disburseCollapseData[Object.keys(obj)[0]].length > 0 ? '' : (this.disburseCollapseData['message'] = 'NO INVOICE');
        } else {
          this.globalExpansion = true;
          let paymentProvider =  this.payProvider == "ALL" ? ["ONE","ACH", "BRANCH"] : [this.payProvider]
          const { data } = await reconciliationApi.post("/getDisburseAggEntries", encodeWithParam({ startDate: `${this.disbAggStartDt?.substr(0,10)} 00:00:00.000`, endDate: `${this.disbAggEndDt?.substr(0,10)} 23:59:59.999`, provider : paymentProvider }));
          const obj = {};
          data.result?.forEach((el) => {
            const key = `${el?.disbursement_date.substr(0,10)}_${el?.invoice_report_type}`;
            if (obj[key] != undefined && el?.invoice_date != null) obj[key].push(el);
            else if (el?.invoice_date != null) obj[key] = new Array(el);
          });
          this.disburseCollapseData = obj;
        }
        } 
        else if (this.displayView === "settlement view") {
          //const { data } = await reconciliationApi.post("/getSettlementAggEntries", encodeWithParam({ invoiceIds : param.settlement_invoice }));
           const { data } = await reconciliationApi.post("/getSettlementAggEntries", encodeWithParam({ invoiceIds : this.invoiceIds}));
            const obj = {};
            data.result?.forEach((el) => {
              const key = el?.settlement_invoice;
              if (obj[key] != undefined && el?.disbursement_date != null) obj[key].push(el);
              else if (el?.disbursement_date != null) obj[key] = new Array(el);
            });
            this.settlementCollpaseData = obj;
          } else if (this.displayView === "transaction view") {
            micromodal.close("recon-loader-modal");
            let transactionFiles = [];
            let transactionStartDate = [];
            let transactionEndDate = [];
            this.transactionEntries.forEach((el) => {
            let fileName = `${el?.transaction_date.substr(0,10)}_${el?.pay_type}`;
            transactionFiles.push(fileName);
            let Date_ = `${el?.transaction_date.substr(0,10)} 00:00:00.000`;
            transactionStartDate.push(Date_);
            })
             
            transactionEndDate = transactionStartDate.map(startDate => {
            // let endDate = new Date(startDate);
            // endDate.setDate(endDate.getDate() + 6);
            // return endDate.toISOString().split('T')[0] + ' 23:59:59.999';
            return this.getWeekEndDate(startDate);
            });
            // if (Object.keys(this.transactionCollpaseData).length <= 0) {
              for (let i = 0; i < transactionStartDate.length; i++) {
                const { data } = await reconciliationApi.post("/getTransactionAggEntries", encodeWithParam({
                startDate: transactionStartDate[i],
                endDate: transactionEndDate[i],
                payType: this.transactionEntries[i].pay_type,
                status: !this.isValue(this.status) || this.status == "ALL" ? ['PENDING','RECONCILED','ADJUSTED'] : [this.status],
              }));
            let obj = {};
            let keys = transactionFiles[i];
            obj[keys] = [];
            data.result.forEach((el) => {
              obj[keys].push(el);
            });
            this.transactionCollpaseData = {
              ...this.transactionCollpaseData,
              [keys]: obj[keys]
            };
          }
          // }
        }
        micromodal.close("recon-loader-modal");
      },
      searchIcon() {
        return this.searchTerm !== "";
      },
      resetSearch() {
        this.searchTerm = "";
        this.fetchFilteredView('view', ['settlement view']);
      },
      isValue(value) {
        return value != "" && value != undefined && value != null;
      },
      // formatDate(date) {
      //   if (date) {
      //     const [year, month, day] = date.substr(0, 10).split("-");
      //     return [month, day, year].join("/");
      //   }
      //   return date;
      // },
      formatDate(dateString, daysToAdd = 0) {
        if (dateString) {
          const originalDate = new Date(dateString);
          const newDate = new Date(originalDate);
          newDate.setDate(originalDate.getDate() + daysToAdd);
          const [year, month, day] = [
            newDate.getFullYear(),
            String(newDate.getMonth() + 1).padStart(2, '0'),
            String(newDate.getDate()).padStart(2, '0')
          ];
        return [month, day, year].join("/");
      }
        return dateString;
      },
      getDate(date) {
        if (date) {
          const [, month, day] = date.substr(0, 10).split("-");
          return [month, day].join("/");
        }
        return date;
      },
      formatMoney(amount, formatNotRequired = false) {
        amount == undefined || isNaN(amount) ? (amount = 0) : amount;
        if(formatNotRequired) return amount;
        return new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(amount)
      },
      redirectProfile(partnerID) {
        document.body.classList.remove("no-scroll");
        this.$router.push({ name: "partner", params: { id: partnerID } });
      },
      totalView(){
        if(this.displayView === 'settlement view' && this.disbursementTotal === this.settlementTotal ){
        return 'green'
      } else if (this.displayView === 'settlement view'){
        return 'red-one'
       } else if (this.displayView === 'disbursement view' && this.disbursementTotal === this.settlementTotal){
        return 'green'
       }
       return 'red-one'
      },
      openReconModal(reconModal, item) {
        item.provider = this.provider || "ONE"
        this.rowItem = item;
        this.reconModalType = reconModal;
        micromodal.show("reconTxnModal");
        document.body.classList.add("no-scroll");
      },
      openReconStatusModal(reconModal, item) {
        item.provider = this.provider || "ONE"
        this.statusItem = item;
        this.reconStatusModalType = reconModal;
        micromodal.show("reconStatusModal");
        document.body.classList.add("no-scroll");
      },
      closeReconStatusModel(rowData){
        this.statusItem = {};
        if(rowData){
          this.settlementEntries?.forEach((item) => {
            if(item.id === rowData.id){
              item.remarks = rowData.remark;
              item.status = rowData.status;
            }
          });
        } 
      },
      async downloadReconCsv() {
        this.downloadLoader = true;
        const { start, end } = this.getDateRange();
        const param = {
          provider: this.displayView === "disbursement view" ? this.provider || "ONE" : this.isValue(this.provider) ? [this.provider] : ["ONE"],
          status: !this.isValue(this.status) || this.status == "ALL" ? ["PENDING", "RECONCILED", "APPROVED", "ADJUSTED"] : [this.status],
          startDate: start,
          endDate: end,
          type: !this.isValue(this.invoiceType) || this.invoiceType == "all" ? ["Earnings", "Tips"] : [this.getInvoiceType(this.invoiceType)],
          offset: 0,
          limit: this.limit
        };
        param.limit = this.perPage;
        param.offset = this.offset;
        const disburseHeaders = [
          "Disbursement date",
          "Type",
          "Disbursement volume",
          "Disbursement amount",
          "Settle volume",
          "Settle amount",
          "Adjusted amount",
          "Balance",
          "Status"
        ];
        const settleHeaders = [
          "Invoice date",
          "Invoice number",
          "Invoice volume",
          "Invoice amount",
          "Disbursement date",
          "Disbursement volume",
          "Disbursement amount",
          "Status",
          "Invoice type"
        ];
        const holdHeaders = [
          "Partner ID",
          "Partner Name",
          "Email ID",
          "phone",
          "Last Failed Payment Provider",
          "Amount"
        ];
        const transactionHeaders = [
          "Transaction Date",
          "Pay Type",
          "Total Volume",
          "Total Amount",
          "To pay Volume",
          "To pay Amount",
          "Hold Volume",
          "Hold Amount",
          "Paid Volume",
          "Paid Amount",
          "Status",
          "Remarks"
        ];
        const holdToPaidHeaders = [
          "Type",
          "Paid Date",
          "ONE Paid",
          "BRANCH Paid",
          "ACH Paid",
          "ACH Returned"
        ]
        const achWebhookHeaders = [
          "Date",
          "Pay Type",
          "Invoice Number",
          "Invoice Amount",
          "Return Amount",
          "Invoice Count",
          "Created",
          "In Process",
          "Completed",
          "Returned"
        ]
        let result;
        const columns = this.displayView === "disbursement view" 
          ? disburseHeaders : this.displayView === "settlement view" && this.provider != "ACH WEBHOOK"
          ? settleHeaders : this.displayView === "hold"
          ? holdHeaders : this.displayView === "transaction view" ? transactionHeaders :this.displayView
          === "hold to paid" ? holdToPaidHeaders : this.displayView === "settlement view" && this.provider === "ACH WEBHOOK" ? achWebhookHeaders
          : [];
        const filename = this.displayView === "disbursement view" 
          ? "Disbursement_summary" : this.displayView === "settlement view" && this.provider != "ACH WEBHOOK"
          ? "Settlement_summary" : this.displayView === "hold"
          ? "Hold_summary" : this.displayView === "transaction view" ? "transaction_summary" : this.displayView
          === "hold to paid" ? "Failed_to_paid_summary" : this.displayView === "settlement view" && this.provider === "ACH WEBHOOK" ?
          "ACH_webhook_summary" : "";
        if (this.displayView === "disbursement view") {
          param.provider = this.payProvider == "ALL" ? ["ONE","ACH", "BRANCH"] : [this.payProvider];
          result = (await reconciliationApi.post("/getDisbursementSummaryCsv", encodeWithParam({ param })))?.data?.result;
        } else if (this.displayView === "settlement view" && this.provider != "ACH WEBHOOK") {
            result = (await reconciliationApi.post("/getSettlementCsv", encodeWithParam({ param })))?.data?.result;
        }else if (this.displayView === "hold") {
            if(!this.dateRange[0] || !this.dateRange[1]){
              param.startDate = null;
              param.endDate = null;
            }
            result = (await reconciliationApi.post("/getHoldCsv", encodeWithParam({ param })))?.data?.result;
        } else if (this.displayView === "transaction view") {
          param.provider =  ["ONE","ACH", "BRANCH"];
          result = (await reconciliationApi.post("/getTransactionCsv", encodeWithParam({ param })))?.data?.result;
        } else if (this.displayView === "hold to paid") {
          let param = {
            startDate : start,
            endDate : end,
            type :!this.isValue(this.invoiceType) || this.invoiceType == "all" ? ["Earnings", "Tips"] : [this.getInvoiceType(this.invoiceType)],
            limit : this.limit,
            offset : 0
          }
          param.limit = this.perPage;
          param.offset = this.offset;
          result = (await reconciliationApi.post("/getHoldToPaidSummaryCsv", encodeWithParam({ param })))?.data?.result;
        } else if (this.displayView === "settlement view" && this.provider === "ACH WEBHOOK") {
          let param = {
            startDate : start,
            endDate : end,
            type :!this.isValue(this.invoiceType) || this.invoiceType == "all" ? ["Earnings", "Tips"] : [this.getInvoiceType(this.invoiceType)],
            limit : this.limit,
            offset : 0
          }
          param.limit = this.perPage;
          param.offset = this.offset;
          result = (await reconciliationApi.post("/getAchWebhookSummaryCsv", encodeWithParam({ param })))?.data?.result;
        }
        else {
          this.toastMessage = "Select any one view to download"
          this.displayToast = true;
          this.toastSeverity = "error"
        }
        if (result?.length > 0) {
          const csv = this.$papa.unparse(result, {
            delimiter: ",",
            columns: Array.from(columns),
          });
          this.$papa.download(csv, filename);
        } else if (this.displayView != "") {
            this.toastMessage = "No data available"
            this.displayToast = true;
            this.toastSeverity = "info"
        }
        this.downloadLoader = false;
      },
      closeToast() {
        this.displayToast = false;
      },
      getInvoiceType(type) {
        return type === "earnings" ? "Earnings" : "Tips";
      },
      async downloadDisbursedCSV(date, item,event) {
        this.isCurrentDate = false;
        const formattedDateTime = new Date().toLocaleString('en-US', { timeZone: 'America/Los_Angeles' });
        const dateOnly = formattedDateTime.split(',')[0].trim();
        const inputDate = new Date(date);
        const formattedDate = `${inputDate.getMonth() + 1}/${inputDate.getDate()}/${inputDate.getFullYear()}`;
        let curDate = new Date(dateOnly);
        this.infTop = (event.target.getBoundingClientRect().top - document.body.getBoundingClientRect().top)-250;
        let disbursementDate = new Date(formattedDate);
        if(curDate < disbursementDate ){
          this.PopupMessage = "For this Future Date, Document will prepare later"
          this.isCurrentDate = true;
        } else {
          const { data } = await reconciliationApi.post("/getDisbursementCSV", encodeWithParam({ startDate: `${date.substr(0,10)} 00:00:00.000`, endDate: `${date.substr(0,10)} 23:59:59.999`, type: item.invoice_report_type, provider: this.provider || "ONE" }));
          let decodedData = JSON.parse(atob(data));
          if(decodedData.result.success){
            window.open(decodedData.result.rows, '_blank');
          } else {
            this.PopupMessage = "Data is not availble, kindly contact Admin Team"
              this.isCurrentDate = true;
          }
        }
        
        // if (data.length > 0) {
        //   downloadFile(data,`${this.formatDate(date).replace(/\//gi, "-")}_${item.invoice_report_type}_disbursed.csv`)
        // }
      },
      async downloadInvoiceCSV(item,event) {
        this.isCurrentDate = false;
        const payload = encodeWithParam({ invoiceId: item.settlement_invoice });
        if (this.displayView == 'settlement view') {
          this.infTop = event.target.getBoundingClientRect().top - document.body.getBoundingClientRect().top-250;
          const { data } = await reconciliationApi.post("/getInvoiceCSVURL", payload);
           let decodedData = JSON.parse(atob(data));
          if (decodedData.result?.success) {
            window.open(decodedData.result.rows, '_blank');
          } else {
            this.PopupMessage = "Data is not availble, kindly contact Admin Team"
            this.isCurrentDate = true;
          }
        } else {
           const { data } = await reconciliationApi.post("/getInvoiceCSV", payload);
        const type = item.pay_type ? item.pay_type : item.invoice_report_type
        if (data.length > 0) {
          downloadFile(data,`${item.settlement_invoice}_${type}.csv`);
        }
        }
      },
      async downloadDailyTransaction(date,value,event) {
        this.isCurrentDate = false;
        const parsedDate = new Date(date);
        const formattedDate = parsedDate.toISOString().split('T')[0];

        const formattedDateTime = new Date().toLocaleString('en-US', { timeZone: 'America/Los_Angeles' });
        const dateOnly = formattedDateTime.split(',')[0].trim();
        const inputDate = new Date(date);
        let curDate = new Date(dateOnly);
        this.infTop = event.target.getBoundingClientRect().top - document.body.getBoundingClientRect().top-250;
        if(curDate < inputDate ){
          this.PopupMessage = "For this Future Date, Document will prepare later";
          this.isCurrentDate = true;
        } else {
          const { data } = await reconciliationApi.post("/getDailyTransactionCSV", encodeWithParam({ startDate: `${formattedDate} 00:00:00.000`, endDate: `${formattedDate} 23:59:59.999`, type: value.pay_type, provider: "ALL" }));
          let decodedData = JSON.parse(atob(data));
          if(decodedData.result?.success){
            window.open(decodedData.result.rows, '_blank');
          } else {
          this.PopupMessage = "Data is not availble, kindly contact Admin Team"
            this.isCurrentDate = true;
          }
        } 
      },
      async downloadHoldToPaidCSV(param,event, date) {
        this.isCurrentDate = false;
        this.infTop = event.target.getBoundingClientRect().top - document.body.getBoundingClientRect().top-250;
        const formattedDateTime = new Date().toLocaleString('en-US', { timeZone: 'America/Los_Angeles' });
        const dateOnly = formattedDateTime.split(',')[0].trim();
        const inputDate = new Date(date);
        let curDate = new Date(dateOnly);
        if(curDate < inputDate ){
          this.PopupMessage = "For this Future Date, Document will prepare later";
          this.isCurrentDate = true;
        } else {
            if((param.ach_paid =='0' || !param.ach_paid) && (param.ach_returned == '0'||!param.ach_returned) 
            && (param.branch_paid =='0' || !param.branch_paid) &&(param.one_paid == '0' || !param.one_paid) ) {
            this.PopupMessage = "No data found, to generate a report.";
            this.isCurrentDate = true;
            } else {
              const { data } = await reconciliationApi.post("/getHoldToPaidCSV", encodeWithParam({ startDate: `${param.paid_returned_date} 00:00:00.000`, endDate: `${param.paid_returned_date} 23:59:59.999`, type: param.pay_type}));
              let decodedData = JSON.parse(atob(data));
              if(decodedData.result?.success){
                window.open(decodedData.result.rows, '_blank');
              } else {
              this.PopupMessage = "Data is not availble, kindly contact Admin Team"
              this.isCurrentDate = true;
            }   
          }
        }
      },
      toggleDisplay(value) {
        if (this.displayView === "disbursement view") {
          this.disburseTableId = value;
        }
        if (this.displayView === "settlement view") {
          this.settlementTableId = value;
        }
        if (this.displayView === "hold") {
          this.holdTableId = value;
        }
        if (this.displayView === "transaction view") {  
          this.transactionTableId = value;
          this.isApiCallEnabled = !this.isApiCallEnabled;
        }

        this.chevronStatus = value;
        if (value) {
          this.getAggEntries();
        } else {
          this.disburseCollapseData = {}
          this.settlementCollpaseData = {}
        }
      },
      getDateRange() {
        let start = DateTime.now().setZone("America/Los_Angeles").startOf("day").minus({ days: 30 }).toString().replace("T", " ").substr(0,23);
        let end = DateTime.now().setZone("America/Los_Angeles").endOf("day").plus({ days: 9 }).toString().replace("T", " ").substr(0,23);
       
        let weekStart = DateTime.now().setZone("America/Los_Angeles").startOf("week").toString().replace("T", " ").substr(0,23);
        let weekEnd = DateTime.now().setZone("America/Los_Angeles").endOf("week").toString().replace("T", " ").substr(0,23);
        if (this.dateRange != null && this.dateRange[1] != null) {
          [start, end] = [`${new Date(this.dateRange[0]).toISOString()?.substr(0,10)} 00:00:00.000`, `${new Date(this.dateRange[1]).toISOString()?.substr(0,10)} 23:59:59.999`];
          return { start, end };
        }
        if(this.displayView === 'hold') {
          this.dateRange = [new Date(weekStart), new Date(weekEnd)];
          return {start:weekStart, end:weekEnd}
        } else {
          return { start, end };
        }
      },
      getHeight(val) {
        return val.payment_provider != "ACH" ? "collapse-height-one" : "collapse-height-ach"
      },
      checkFutureDate(item) {
        const disbDate = new Date(item.disbursement_date.substr(0,10));
        const today = new Date(DateTime.now().setZone("America/Los_Angeles").toFormat("yyyy-MM-dd"));
        return today > disbDate && item.balance > 0 ? true : false
      },
      fetchData(event) {
        this.loader = true;
        this.currentPage = Number(event.pageNumber);
        this.offset = event.offset;
        this.fetchFilteredView('','',false);
      },
      fetchDate(data,type, dateRange){
        this.dateRange = dateRange;
        this.fetchFilteredView(data, type, dateRange);
      },
      selectDownload() {
        this.downloadReconCsv()
      },
      replaceHypens(date) {
        return date.replace(/-/g, "/")
      }
    },
    //adding comment
  };
</script>

<style scoped>
  .inherit-flex {
    flex: inherit !important;
  }

  .width-invoice{
    width: 120px;
  }
  .margin-disbursements {
    margin-left: calc(60% - 120px);
  }

  .disbursement-width{
    width: 140px;
  }
  .margin-settlement-invoice {
    margin-left: calc(38% - 148px);
  }

  .accordian-tr {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .background-white {
    background-color: white;
  }

  .tr-height {
    height: 6px;
  }

  .font-14 {
    font-size: 14px !important;
  }
  
  .font-12 {
    font-size: 12px !important;
  }

  .collapse-height-ach {
    height: 64px;
  }

  .collapse-height-one {
    height: 32px;
  }

  tbody tr:nth-child(odd) {
    background: none;
  }

  .dot {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
  }

  .dot-small {
    margin-top: 4px;
    margin-right: 3px;
    height: 6px;
    width: 6px;
    border-radius: 50%;
    display: inline-block;
  }
  .dot-col-align{
    display: inline-flex;
    align-items: center;
  }
  .dot-col-align .dot{
    margin-right: 3px;
  }
  .reconciled {
    background-color: #2a8703;
  }

  .pending {
    background-color: #ffab00;
  }

  .approved {
    background-color: #2c92f3;
  }

  .failed {
    background-color: red;
  }

  .settlement-tr {
    width: 100%;
    height: 32px;
  }

  .none a {
    text-decoration: none;
  }

  .invoice-tooltip-align {
    margin-top: 16px;
    margin-left: calc(-15% - 5px) !important;
  }

  .summary-tooltip-align {
    margin-left: -150px;
    margin-top: 40px;
  }

  .disburse-tooltip-align {
    margin-left: -72px;
    margin-top: 16px
  }

  .balance-tooltip-align {
    margin-left: -54px;
    margin-top: 14px;
  }

  .p-right-0 {
    padding-right: 0;
  }
  .totals-text {
    font-size: 13px;
    font-weight: 600;
    margin: 10px;
    text-align: center;
  }
  .totals-num {
    font-size: 22px;
    font-weight: 600;
    color: red;
    text-align: center;
    margin-top: 4px;
    padding: 0 10px;
  }
  input[type='search'] {
    width: 272px;
  }
  .green{
    color: green;
  }
  .black{
    color: black;
  }
  .red-one{
    color: red;
  }
  .no-data{
    color: var(--text-color);;
  }

  .toggle-icon{
    display: flex;
    justify-content: center;
  }
  .data-table{
    margin-top: -40px;
  }
  .earning-container{
    padding-right: 0px;
  }
  .transaction-align{
    margin-right: 60px;
  }
  .align-transaction{
    margin-bottom: -20px;
  }
  .payProvider-container{
    display: flex;
    flex-direction: column;
    height: 100px;
  }
  .tr-height-transaction {
    height: 10px;
    color: black;
    font-weight: 600;
  }
/* .child-rows{
  background-color:#e9f9ff;;
  width: 40px;
} */
.dot-col-align-trans{
  display: flex;
  gap:5px
}
.scrollable-table {
  width: 25%;
  position: absolute;
  box-shadow: 2px 2px 9px 2px rgba(0,0,0,0.5);
}
.table-content{
  overflow-y: auto;
  max-height: 150px;
}
.transaction-data {
  overflow-y: auto;
}
.provider-table{
  width: 100%;
  border-collapse: collapse;
  /* height: 100px; */
}
th, td {
  border: 0.8px solid #ddd;
  padding: 8px;
  text-align: center;
  line-height: 0.5;
}
.heading{
  padding: 0 0;
  font-weight: 700;
  border-bottom: none;
}
.provider-card{
 background-color: #ebf2ee;
}
th{
  color: black;
}
.box-size{
  width: 300px;
  /* border: 0px; */
}
.sticky-header {
  position: sticky;
  top: 0;
  background-color: #fff; /* Adjust the background color as needed */
  z-index: 1;
}
.heading {
  border-left: none;
  border-right: none;
}
.menu{
  background-color: #eee;
}
.number-right{
  text-align: right;
}
.number-center {
  text-align: center;
}
a.button.secondary.disabled {
  pointer-events: none;
  opacity: 0.5;
  cursor: not-allowed;
}
</style>